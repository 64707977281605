<template functional>
  <ul class="storeList">
    <li v-for="store in props.lists" :key="store.id">
      <router-link :to="{name: 'StoreDetail', params: {id: store.id}}" v-scroll-to="{el: '#app' ,container: '.container',duration: 300,easing: 'linear',offset: -46,force: true,x: false,y: true}">
        <span class="storeNameBox">
          <span class="brandName">{{store.brand_name_en}}</span>
          <span class="storeName">{{store.name}}店</span>
        </span>
        <span class="arrow"><font-awesome-icon icon="chevron-right" /></span>
      </router-link>
    </li>
  </ul>
</template>

<script>

export default {
  props: ['lists']
}
</script>


<style scoped>
section{
  /* background: red; */
}

.storeList{
  list-style: none;
  padding-left: 0;
  margin: 0;
  border-top: 1px solid #FAFAFA;
  border-bottom: 1px solid #F0F0F0;
}

.storeList li{
  position:relative;
  border-bottom: 1px solid #FAFAFA;
}

.storeList li a{
  background-color: #FFF;
  padding: 16px 24px;
  display: flex;
  align-items: center;
}


.arrow{
  display: block;
}

.storeNameBox{
  display: block;
  width: 100%;
  padding: 0 16px 0 0;
}

.brandName{
  display: block;
  font-size: .75rem;
  font-weight: bold;
  color: #AAA;
}

.storeName{
  display: block;
  font-size: .9rem;
  padding-top: 3px;
}
</style>
