<template>
  <div class="">
    <p class="back__link">
      <router-link :to="backPath" v-scroll-to="{el: '#brandNav' ,container: '.container',duration: 300,easing: 'linear',offset: -46,force: true,x: false,y: true}">
        <span class="back__arrow"><font-awesome-icon icon="chevron-right" /></span>
        <span class="name">市区町村から選ぶ</span>
      </router-link>
    </p>
    <h1 class="list_title">{{listTitle}}</h1>
    <storeList :lists="lists" />
  </div>
</template>

<script>
import storeList from "@/components/parts/storeList.vue";
import {Mixin} from '@/mixins/common'
import axios from 'axios';
import { mapActions } from "vuex"

export default {
  data() {
    return {
      lists:{},
      listTitle: '',
      backTitle: '',
      backPath: '',
      title: '',
      description: ''
    };
  },
  components: {
    storeList
  },
  mixins:[Mixin],
  created (){
    this.pageInit(this.$route)
  },
  mounted () {
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      'setshareData'
    ]),
    refresh() {
      this.lists = {}
      this.listTitle = ''
      this.title = ''
      this.description = ''
    },
    pageInit(route) {
      this.refresh()
      this.getStoreList(route)
    },
    async getStoreList(route){
      let url = this.api

      if(route.params.code) url += '/brands/' + route.params.code

      url += '/stores/lists/'

      if(route.params.prefecture_code) url += route.params.prefecture_code + '/'
      if(route.params.city_code) url += route.params.city_code + '/'

      // console.log(url);

      await axios.get(url)
      .then((res) => {
        this.lists = res.data

        // タイトルと戻るボタンの処理
        if(route.params.code){
          this.listTitle += res.data[0].brand_name_en + 'の'
        }
        if(route.params.prefecture_code){
          this.listTitle += res.data[0].prefecture_name_ja
          this.backPath += '/brand/' + route.params.code + '/stores/'
        }
        if(route.params.city_code){
          this.listTitle += res.data[0].city_name_ja
          this.backPath += route.params.prefecture_code
        }

        this.listTitle += 'の店舗一覧'

        this.title = this.listTitle
        this.description = this.listTitle + 'ページ。'
        this.$emit('updateHead')

        // sharedata
        const shareData = {}
        shareData.title = this.title
        shareData.text = this.description
        shareData.url = window.location.href
        this.setshareData(shareData)


      })
      .catch((e) =>{
        // console.log(e);
        // console.log('item imgs not found')
      })
    }
  }
}
</script>
